import {
  type Component,
  createEffect,
  createMemo,
  createSignal,
} from "solid-js";
import { useSubmission } from "@solidjs/router";
import { editCartItemAction } from "~/services/cart";
import { debounce } from "@solid-primitives/scheduled";
import { PT } from "~/utils/products";
import { TextFieldInput } from "~/components/inputs";
import { Icon } from "solid-heroicons";
import { plus, minus } from "solid-heroicons/outline";
import { useCheckoutContext } from "~/utils/contexts";
import { checkError } from "~/services/roma-api/errors";

export const QtyCounter: Component<{
  id: string;
  disabled?: boolean;
  quantity: number;
  updateQuantity?: VoidFunction;
  removeLine?: VoidFunction;
  productType: PT;
  line?: any;
}> = (props) => {
  const { updateQuantity, removeLine } = useCheckoutContext();
  const [hasError, setHasError] = createSignal(false);
  const isBeingEdited = useSubmission(editCartItemAction);

  // minumum quantities for product types:
  const minimum = createMemo(() => {
    if (
      ![PT.PHOTOFRAME, PT.GALLERYFRAME, PT.CONTRACT].includes(props.productType)
    ) {
      return 1;
    } else if (props.productType == PT.PHOTOFRAME) {
      return 2;
    } else if (props.productType == PT.GALLERYFRAME) {
      return 6;
    } else if (props.productType == PT.CONTRACT) {
      return 3000;
    } else {
      return 1;
    }
  });

  const updateQtyDebounce = debounce(
    async (id: string, qty: number, type: PT) => {
      try {
        setHasError(false);
        await updateQuantity(id, qty, type);
      } catch (error) {
        const err = checkError(error)
        if (import.meta.env.DEV) {
          console.log("[updateQtyDebounce] error caught: ", err);
        }
        setHasError(true);
        throw err;
      }
    },
    1000
  );

  const decrement = (id: string, qty: number, type: PT) => {
    const qtyInput = document.getElementById(
      `qtyInput-${props.id}`
    ) as HTMLInputElement;
    if (qtyInput) {
      if (qtyInput.value && parseInt(qtyInput.value) > minimum()) {
        const newVal = parseInt(qtyInput.value) - 1;
        qtyInput.value = newVal.toString();
        updateQtyDebounce(id, newVal, type);
      }
    }
  };

  const increment = (id: string, qty: number, type: PT) => {
    const qtyInput = document.getElementById(
      `qtyInput-${props.id}`
    ) as HTMLInputElement;
    if (qtyInput) {
      const newVal = parseInt(qtyInput.value) + 1;
      qtyInput.value = newVal.toString();
      updateQtyDebounce(id, newVal, type);
    }
  };

 
  return (
    <div
      class="flex flex-col gap-1 items-center"
      // ! TESTING
      // classList={{
      //   "bg-red-500":
      //     isBeingEdited?.input?.[0] === props.id && isBeingEdited.pending,
      // }}
    >
      <p class="text-xs text-roma-dark-grey text-center">
        {[PT.LENGTH, PT.CONTRACT].includes(props.productType)
          ? "Linear Ft"
          : "QTY"}
      </p>
      <div
        class="flex justify-around w-36 "
        classList={{ " border border-red-500 bg-red-50": hasError() }}
      >
        <button
          aria-label="Decrement quantity by 1"
          class="p-2 border border-r-0 border-gray-300 disabled:text-roma-medium-grey disabled:cursor-not-allowed"
          classList={{
            hidden: !!props.disabled,
          }}
          disabled={props.quantity == minimum()}
          onClick={() => {
            decrement(props.id, props.quantity - 1, props.productType); //updateQuantity(props.id, props.quantity - 1);
          }}
        >
          <Icon path={minus} class="w-4" />
        </button>
        <TextFieldInput
          name="quantity"
          id={`qtyInput-${props.id}`}
          type="number"
          class={`rounded-none text-center ${
            props.line?.RequiresBackorderSelection || hasError()
              ? "!bg-inherit"
              : ""
          }`}
          onChange={(val) => {
            updateQtyDebounce(props.id, Number(val), props.productType);
          }}
          defaultValue={props.quantity.toString()}
          disabled={props.disabled}
        />
        <button
          aria-label="Increment quantity by 1"
          class="p-2 border border-l-0 border-gray-300"
          classList={{
            hidden: !!props.disabled,
          }}
          onClick={() => {
            increment(props.id, props.quantity + 1, props.productType);
          }}
        >
          <Icon path={plus} class="w-4" />
        </button>
      </div>
      <button
        onClick={() => removeLine(props.id)}
        class="text-xs text-roma-blue text-center"
      >
        Remove
      </button>
    </div>
  );
};
