import { Show, Switch, Match, createMemo, Component } from "solid-js";
import { useNavigate } from "@solidjs/router";
import { capitalize } from "~/utils/helpers";
import { CartDetail } from "~/services/roma-api/cart/types";
import { type FormattedCart } from "~/routes/(layout)/checkout/[cart_id]";
import {
  ChopLineItem,
  JoinLineItem,
  LengthLineItem,
  MirrorLineItem,
  StackLineItem,
} from "~/services/roma-api/cart/types-lineItems";
import { PT, PTLabels, StackPTLabels } from "~/utils/products";
import {
  AllowanceLabels,
  type AllowanceOption,
  OrientationLabels,
  type OrientationOption,
  WedgeLabels,
  type WedgeOption,
  BevelLabels,
  type BevelOption,
  CuttingLabels,
  SubItem,
} from "~/services/roma-api/cart/types-productOrdering";
import { frameDictionary } from "~/utils/builtFrames";
import { facilityMapping } from "../order-page";
import { cuttingInstrOptions } from "../order-page/product-types";
import { typeguard } from "~/utils/typeguard";
import { Icon } from "solid-heroicons";
import { exclamationCircle } from "solid-heroicons/solid";


export type LineDetailsProps = {
  line: FormattedCart["Lines"][number];
};


// TODO - Refactor this


export const LineDetails: Component<LineDetailsProps> = (props) => {
  const navigate = useNavigate();
  const item = () => props.line.SubItems[0];

  // For stacks, check if *any of the layers have a wedge
  const stackWedge = createMemo(() => {
    if (props.line.Type == "stack") {
      for (const x of props.line.SubItems) {
        if (x.Wedge) {
          return true;
        }
      }
    }
    return false;
  });

  const Dimensions = () => {
    return (
      <>
        <Show
          when={props.line.Type == "stack"}
          fallback={
            <>
              <span class="fraction font-normal">
                {item().Width} {item().WidthFraction ?? ""}"
              </span>
              <span class="text-xs mx-1">&#10005;</span>
              <span class="fraction font-normal">
                {item().Length} {item().LengthFraction ?? ""}"
              </span>
              <span>({item().Layer == "O" ? "Outside" : "Inside"})</span>
            </>
          }
        >
          <span class="fraction font-normal">
            {(props.line as StackLineItem).FrameSize?.Width}{" "}
            {(props.line as StackLineItem).FrameSize?.WidthFraction ?? ""}"
          </span>
          <span class="text-xs mx-1">&#10005;</span>
          <span class="fraction font-normal">
            {(props.line as StackLineItem).FrameSize?.Length}{" "}
            {(props.line as StackLineItem).FrameSize?.LengthFraction ?? ""}"
          </span>
          <span>
            (
            {(props.line as StackLineItem).FrameSize?.Sizing == "outside"
              ? "Outside"
              : "Inside"}
            )
          </span>
        </Show>
      </>
    );
  };

  return (
    <>
      <div class="ml-4 self-start flex flex-col gap-0 h-full">
        <Show
          when={props.line.Type === PT.STACK}
          fallback={
            <>
              <p class="text-base font-bold text-gray-900">{item().Moulding}</p>
              <Show when={props.line.Tag}>
                <p class="text-sm text-roma-medium-grey">{props.line.Tag}</p>
              </Show>
              {/* <p class="text-sm  text-gray-900">
                {cart().Products[item()?.Moulding].Collection},{" "}
                {cart().Products[item()?.Moulding].Colour}
              </p> */}
              <p class="text-sm font-light text-roma-dark-grey">
                {PTLabels[props.line.Type as PT]}
              </p>
            </>
          }
        >
          <>
            <p class="text-sm font-bold text-gray-900">
              {(props.line as StackLineItem).FrameSize.Sizing == "inside"
                ? [...props.line.SubItems]
                    .reverse()
                    .map((item) => item?.Moulding)
                    .join(" / ")
                : props.line.SubItems.map((item : SubItem) => item?.Moulding).join(" / ")}
            </p>
            <Show when={props.line.Tag}>
              <p class="text-sm text-roma-medium-grey">{props.line.Tag}</p>
            </Show>
            <p class="text-sm font-light text-roma-dark-grey">
              {
                StackPTLabels[
                  props.line.Type +
                    capitalize((props.line as StackLineItem).Operation) +
                    props.line.SubItems.length
                ]
              }
            </p>
          </>
        </Show>
        <div class="text-sm font-light text-roma-dark-grey">
          <Switch>
            <Match
              when={[PT.CHOP, PT.JOIN, PT.MIRROR, PT.STACK].includes(
                props.line.Type
              )}
            >
              <Dimensions />
              <Show when={(props.line as CJS).Allowance}>
                <p>
                  Allowance:{" "}
                  {
                    AllowanceLabels[
                      (props.line as CJS).Allowance as AllowanceOption
                    ]
                  }
                </p>
              </Show>
              <Show when={item().Orientation}>
                <p>
                  Orientation:{" "}
                  {OrientationLabels[item().Orientation as OrientationOption]}
                </p>
              </Show>
              <Show when={item().Wedge && props.line.Type !== PT.STACK}>
                <p>{WedgeLabels[item().Wedge as WedgeOption]}</p>
              </Show>
              <Show when={props.line.Type == PT.STACK && stackWedge() == true}>
                <p>Wedged</p>
              </Show>
              <Show when={(props.line as MirrorLineItem)?.Bevel}>
                <p>
                  {
                    BevelLabels[
                      (props.line as MirrorLineItem).Bevel as BevelOption
                    ]
                  }
                </p>
              </Show>
            </Match>
            <Match when={[PT.LENGTH, PT.CONTRACT].includes(props.line.Type)}>
              <span class="fraction font-light">
                {item().Length} {item().LengthFraction ?? ""}ft
              </span>
              <Show when={(props.line as LengthLineItem).Cutting}>
                <span>
                  {" "}
                  (
                  <Show
                    when={cuttingInstrOptions
                      .slice(0, -1)
                      .map((item) => item.value)
                      .includes((props.line as LengthLineItem).Cutting)}
                    fallback={
                      <span>
                        {(props.line as LengthLineItem).Cutting.substring(
                          0,
                          15
                        ) + "..."}
                      </span>
                    }
                  >
                    {CuttingLabels[(props.line as LengthLineItem).Cutting]}
                  </Show>
                  )
                </span>
              </Show>
            </Match>
            <Match when={props.line.Type == PT.RAIL}>
              <span class="fraction font-light">
                {item().Length} {item().LengthFraction ?? ""}"
              </span>
              <span class="font-light">
                ({item().Layer == "O" ? "Outside" : "Inside"})
              </span>
              <Show when={item().Orientation}>
                <p>
                  Orientation:{" "}
                  {OrientationLabels[item().Orientation as OrientationOption]}
                </p>
              </Show>
            </Match>
            <Match when={props.line.Type == PT.BOX}>
              <p class="">Box of {props.line.CalculatedQuantity} ft.</p>
            </Match>
            <Match when={props.line.Type == PT.GALLERYFRAME}>
              <p>
                {
                  frameDictionary[item().SKU?.split("-")[1].split("M")[0]]
                    ?.width
                }
                " <span class="text-xs mx-px">&#10005;</span>{" "}
                {
                  frameDictionary[item().SKU?.split("-")[1].split("M")[0]]
                    ?.height
                }
                "{" "}
              </p>
            </Match>
            <Match when={props.line.Type == PT.PHOTOFRAME}>
              <p>
                {frameDictionary[item().SKU?.split("-")[1]]?.width}"{" "}
                <span class="text-xs mx-px">&#10005;</span>{" "}
                {frameDictionary[item().SKU?.split("-")[1]]?.height}"{" "}
              </p>
            </Match>
          </Switch>
          <Show when={props.line.Plant && props.line.Plant !== ""}>
            <p>Ships From: {facilityMapping[props.line.Plant]}</p>
          </Show>
          <Show when={props.line.FreightAccepted}>
            <p class="text-orange-400">Additional Freight Charge</p>
          </Show>
          <Show when={!!props.line.BackOrder}>
            <p class="text-orange-400 text-sm">Place on Backorder</p>
          </Show>
          <Show when={!!props.line.CancelBackOrder}>
            <p class="text-orange-400 text-sm">Cancel on Backorder</p>
          </Show>
        </div>
        <button
          class="text-roma-blue text-xs self-start flex items-center"
          onClick={() => {
            navigate(`/order?editCart=${props.line.ID}`);
          }}
        >
          <span>Edit</span>{" "}
          <Show when={!!props.line?.RequiresBackorderSelection}>
            <Icon
              path={exclamationCircle}
              class="w-5 h-5 inline ml-1 text-red-500 animate-pulse"
            />
          </Show>
        </button>
      </div>
    </>
  );
};

type CJMS = ChopLineItem | JoinLineItem | MirrorLineItem | StackLineItem;

type CJS = ChopLineItem | JoinLineItem | StackLineItem;
