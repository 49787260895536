import { Component, Show, createSignal, Suspense } from "solid-js";
import { StorageSetter } from "~/utils/types";
import { useCheckoutContext } from "~/utils/contexts";
import { Icon } from "solid-heroicons";
import { users, xMark, cursorArrowRipple } from "solid-heroicons/outline";
import { CheckoutStore } from "~/routes/(layout)/checkout/[cart_id]";
import { BaseLoader } from "~/components/utility";
import { CustomerSearch } from "~/components/account/admin";
import type { ComboOption } from "~/components/inputs";

export const BPSCheckout: Component<{
  onClose?: VoidFunction;
}> = (props) => {
  const { setCheckoutData } = useCheckoutContext();
  const [showBPS, setShowBPS] = createSignal(false);
  const onClose = () => {
    setShowBPS(false);
    setCheckoutData("CustomerID", undefined);
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <section class="w-full  bg-faint-blue rounded-lg shadow-sm border border-gray-300 flex items-stretch p-4 mt-2">
      <Suspense fallback={<BaseLoader width={5} height={5} />}>
        <button class="border-r border-gray-300 flex items-center justify-center shrink-0">
          <Icon
            path={showBPS() ? xMark : users}
            class={`w-7 h-7 mr-4 shrink-0 text-roma-dark-grey ${
              showBPS() ? "cursor-pointer hover:text-black" : "cursor-default"
            }`}
            onClick={() => (showBPS() ? onClose() : undefined)}
          />
        </button>
        <div class=" grow px-4 text-roma-dark-grey h-full">
          <Show when={!showBPS()}>
            <button
              class="rounded-lg px-3 py-2 flex items-center justify-center text-sm bg-roma-blue text-white "
              onClick={() => setShowBPS(true)}
            >
              <Icon path={cursorArrowRipple} class="w-5 h-5 mr-1" />
              <span>Place Customer Order</span>
            </button>
          </Show>
          <Show when={showBPS()}>
            <div class="flex flex-col gap-2">
              <p class="text-xs">
                Note: Orders placed on behalf of customers can contain{" "}
                <span class="italice">only</span> corner samples
              </p>
              <CustomerSearch
                placeholder="Search by name or customer number"
                onChange={(option: ComboOption) => {
                  if (option) {
                    setCheckoutData(
                      "CustomerID",
                      option.value.replace(/^0+/, "")
                    );
                  }
                }}
                multiple={false}
              />
            </div>
          </Show>
        </div>
      </Suspense>
    </section>
  );
};
