import { Component, createResource, Show } from "solid-js";
import { useSessionContext } from "~/utils/contexts";
import { ComboBox, type ComboOption } from "~/components/inputs";

type CustomerSearchProps = {
  onChange?: (val: any) => void;
  onClear?: VoidFunction;
  multiple?: boolean;
  placeholder?: string;
  style?: "square" | undefined;
};

export const CustomerSearch: Component<CustomerSearchProps> = (props) => {
  const { session } = useSessionContext();

  // TODO!!
  // make endpoint/fns for /admin/customer..

  const [customers] = createResource(
    () => session()?.token,
    async (token) => {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_ROMA_API}/admin/customers`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.ok) {
          const customerList = await response.json();
          const reduced = [...customerList.Results].reduce((memo, item) => {
            memo[item.CustomerNumber] = item.Name;
            return memo;
          }, {});
          const formatted = Object.entries(reduced).map(
            ([CustomerNumber, Name]) => ({
              label: Name,
              value: CustomerNumber,
              disabled: false,
            })
          );
          formatted.sort((a, b) => {
            const textA = (a.label as string).toUpperCase();
            const textB = (b.label as string).toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
          return formatted;
        } else {
          const errorText = await response.text();
          throw new Error(
            `Request failed with status code ${response.status}. Error: ${errorText}`
          );
        }
      } catch (err) {
        console.error("ERROR:", err);
        return [];
      }
    }
  );

  return (
    <Show when={customers()}>
      <ComboBox
        options={customers() as ComboOption[]}
        placeholder={
          props.placeholder ??
          `Filter customers (${customers()?.length} results)`
        }
        onChange={props.onChange}
        onClear={props.onClear}
        style={props.style}
        multiple={props.multiple}
      />
    </Show>
  );
};
