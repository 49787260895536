import { createSignal, Show, createMemo } from "solid-js";
import { useCheckoutContext } from "~/utils/contexts";
import { debounce } from "@solid-primitives/scheduled";
import { Motion, Presence } from "solid-motionone";
import { getRegionsByCountryCode } from "~/utils/countries-states";
import { TextFieldInput, SelectBox } from "~/components/inputs";
import type { OrderAddress } from "~/services/roma-api/admin/orders";

export const CustomShipLocation = () => {
  const { setCheckoutData, checkoutData } = useCheckoutContext();
  const [disabled, setDisabled] = createSignal(false);
  const [editMode, setEditMode] = createSignal(true);
  // TODO this was written but never used - buggy? Implement on the onChnges and test:
  const setAddress = debounce(
    (storeValue: keyof OrderAddress, value: string) => {
      setCheckoutData("customLocation", storeValue, value);
    },
    200
  );

  const regionsList = createMemo(() => {
    if (!checkoutData.customLocation.Country) return [];
    return getRegionsByCountryCode(checkoutData.customLocation.Country).map(
      (region) => ({ label: region.name, value: region.abbreviation })
    );
  });

  const handleSubmit = () => {
    console.log(JSON.stringify(checkoutData.customLocation));
  };

  return (
    <>
      <Presence exitBeforeEnter>
        <Show when={editMode() == false}>
          <Motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20, transition: { duration: 0.2 } }}
            transition={{ duration: 0.7 }}
            class="bg-faint-blue p-5"
          >
            <div class="flex max-md:flex-col gap-4 font-light text-sm text-roma-dark-grey">
              <div class="max-md:w-full md:basis-2/3">
                <h4 class="text-base font-medium">Ship To:</h4>
                <p>
                  {checkoutData.customLocation.Number}{" "}
                  {checkoutData.customLocation.Street}
                </p>
                <Show when={checkoutData.customLocation.Street2}>
                  {checkoutData.customLocation.Street2}
                </Show>
                <p>
                  {checkoutData.customLocation.City},{" "}
                  {checkoutData.customLocation.Region}
                </p>
                <p>
                  {checkoutData.customLocation.Country},{" "}
                  {checkoutData.customLocation.PostalCode}
                </p>
              </div>
              <div class="max-md:w-full md:basis-1/3 flex flex-col justify-between">
                <div>
                  <h4 class="text-base font-medium">Contact Details:</h4>
                  <p>{checkoutData.customLocation.Recipient}</p>
                  <p>{checkoutData.customLocation.Email}</p>
                  <p>{checkoutData.customLocation.Phone}</p>
                </div>
                <button
                  class="text-roma-blue font-medium self-end p-2"
                  onClick={() => {
                    setDisabled(false);
                    setEditMode(true);
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
          </Motion.div>
        </Show>
      </Presence>

      <Presence exitBeforeEnter>
        <Show when={editMode() == true}>
          <Motion.form
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20, transition: { duration: 0.2 } }}
            transition={{ duration: 0.7 }}
            class=" bg-faint-blue px-4 py-6"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
              setDisabled(true);
              setEditMode(false);
            }}
          >
            <fieldset class="flex flex-col gap-3" disabled={disabled()}>
              <TextFieldInput
                name="Recipient"
                label="Recipient"
                class="!bg-white"
                labelClass="text-roma-dark-grey"
                value={checkoutData.customLocation.Recipient}
                onChange={(val) => {
                  setCheckoutData("customLocation", "Recipient", val);
                }}
                placeholder="First & Last Name"
                disabled={disabled()}
                required
                autocomplete="name"
                maxLength={40}
              />
              <div class="flex max-md:flex-col gap-2 child:basis-1/2">
                <TextFieldInput
                  name="email"
                  type="email"
                  label="Email Address"
                  class="!bg-white"
                  labelClass="text-roma-dark-grey"
                  value={checkoutData.customLocation.Email}
                  onChange={(val) => {
                    setCheckoutData("customLocation", "Email", val);
                  }}
                  placeholder="Enter your email"
                  required
                  autocomplete="email"
                  maxLength={241}
                />
                <TextFieldInput
                  name="Phone"
                  label="Phone Number"
                  class="!bg-white"
                  labelClass="text-roma-dark-grey"
                  value={checkoutData.customLocation.Phone}
                  onChange={(val) => {
                    setCheckoutData("customLocation", "Phone", val);
                  }}
                  placeholder="+1 (123) 456-789"
                  required
                  autocomplete="tel"
                  maxLength={30}
                />
              </div>
              <div class="flex max-md:flex-col gap-2">
                <TextFieldInput
                  name="Number"
                  label="Number"
                  rootClass="!basis-1/4"
                  class="!bg-white"
                  labelClass="text-roma-dark-grey"
                  value={checkoutData.customLocation.Number}
                  onChange={(val) => {
                    setCheckoutData("customLocation", "Number", val);
                  }}
                  placeholder="Street Number"
                  required
                  maxLength={10}
                />
                <TextFieldInput
                  name="Street"
                  label="Street"
                  rootClass="w-full"
                  class="!bg-white !w-full"
                  labelClass="text-roma-dark-grey"
                  value={checkoutData.customLocation.Street}
                  onChange={(val) => {
                    setCheckoutData("customLocation", "Street", val);
                  }}
                  placeholder="Street Name"
                  required
                  autocomplete="address-line1"
                  maxLength={40}
                />
              </div>
              <div class="flex max-md:flex-col gap-2">
                <TextFieldInput
                  name="Street2"
                  label="Street Details"
                  rootClass="basis-1/2"
                  class="!bg-white"
                  labelClass="text-roma-dark-grey"
                  value={checkoutData.customLocation.Street2}
                  onChange={(val) => {
                    setCheckoutData("customLocation", "Street2", val);
                  }}
                  placeholder="Apt, suite, bldg, etc. (Optional)"
                  autocomplete="address-line2"
                  maxLength={40}
                />
                <TextFieldInput
                  name="City"
                  label="City"
                  rootClass="basis-1/2"
                  class="!bg-white"
                  labelClass="text-roma-dark-grey"
                  value={checkoutData.customLocation.City}
                  onChange={(val) => {
                    setCheckoutData("customLocation", "City", val);
                  }}
                  placeholder="Enter your city or town"
                  required
                  autocomplete="address-level2"
                  maxLength={40}
                />
              </div>
              <div class="flex max-md:flex-col gap-2 items-end">
                <SelectBox
                  class="max-md:w-full md:basis-1/3"
                  background="bg-white"
                  placeholder="Select country"
                  label="Country"
                  options={[
                    { label: "Canada", value: "CA" },
                    { label: "USA", value: "US" },
                  ]}
                  onChange={(option) => {
                    setCheckoutData(
                      "customLocation",
                      "Country",
                      option.value as string
                    );
                  }}
                />
                <SelectBox
                  class="max-md:w-full md:basis-1/3"
                  label="Region"
                  background="bg-white"
                  placeholder="Select region"
                  options={regionsList()}
                  onChange={(option) =>
                    setCheckoutData(
                      "customLocation",
                      "Region",
                      option?.value as string
                    )
                  }
                  required
                />
                <TextFieldInput
                  name="PostalCode"
                  label="Postal / ZIP"
                  rootClass="max-md:w-full md:basis-1/3"
                  class="!bg-white"
                  labelClass="text-roma-dark-grey"
                  value={checkoutData.customLocation.PostalCode}
                  onChange={(val) => {
                    setCheckoutData("customLocation", "PostalCode", val);
                  }}
                  placeholder="Enter your postal / zip code"
                  required
                  autocomplete="postal-code"
                  maxLength={10}
                />
              </div>
            </fieldset>
            <button
              type="submit"
              class="w-full p-2 mt-4 border rounded-sm bg-white/40 hover:bg-white transition text-sm"
            >
              Set new shipping address
            </button>
          </Motion.form>
        </Show>
      </Presence>
    </>
  );
};
